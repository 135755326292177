exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-carbon-market-js": () => import("./../../../src/pages/carbon-market.js" /* webpackChunkName: "component---src-pages-carbon-market-js" */),
  "component---src-pages-dashbold-js": () => import("./../../../src/pages/dashbold.js" /* webpackChunkName: "component---src-pages-dashbold-js" */),
  "component---src-pages-greatcollab-js": () => import("./../../../src/pages/greatcollab.js" /* webpackChunkName: "component---src-pages-greatcollab-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home-2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-home-3-js": () => import("./../../../src/pages/home-3.js" /* webpackChunkName: "component---src-pages-home-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-retrofit-js": () => import("./../../../src/pages/retrofit.js" /* webpackChunkName: "component---src-pages-retrofit-js" */)
}

